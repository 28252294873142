<template>
  <div class="spaceManage">
    
    <ds-header title="空间管理"></ds-header>
    <div style="display: flex">
      <a-card style="width: 400px; margin-right: 50px">
          <a-tree-select
            style="width: 310px"
            showArrow
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode || document.body;
              }
            "
            :default-value="{ key: defaultSheet }"
            :defaultActiveFirstOption="true"
            :maxTagTextLength="22"
            :maxTagCount="2"
            :treeDefaultExpandAll="true"
            v-model="form.projectIds"
            placeholder="请选择"
            :filter-option="filterOption"
            :replaceFields="replaceTypeTreeFields"
            @change="selectHandle"
            :treeData="villageList" 
          >
          </a-tree-select>
        <div>
          <a-input-search
            v-model="spaceName"
            placeholder="请输入空间名称查询"
            style="width: 310px; margin-top: 10px"
            @change="onSearch"
            key="unique-input-key"
          />
          <a-tree
            v-if="treeData.length > 0"
            :replaceFields="{ key: 'id', title: 'space_name', children: 'son_list' }"
            :defaultExpandedKeys="expandSecondLevel"
            style="margin-top: 10px; position: relative"
            :tree-data="treeData"
            @expand="onExpand"
            
            @select="treeSelect"
            key="unique-tree-key"
          >
            <template slot="handle" slot-scope="item">
              <!--item.title 为treeData的 title 字段-->
              <a-tooltip>
                <template slot="title">
                  {{item.space_name}}
                </template>
                  <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 180px;display: inline-block;" >{{ item.space_name }}</span>
              </a-tooltip>
              <a-icon
                    style="position: absolute; right: 0px; margin-top: 8px;"
                    @click.stop="treeHandleClick(item)"
                    type="plus"
                  />
            </template>
          </a-tree>
          <div v-else style="text-align: center; margin-top: 70px">暂无数据</div>
        </div>
      </a-card>
      <div>
        <a-form layout="inline">
          <div style="display: flex; justify-content: space-between">
            <a-form-item label="空间名称：" :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
              <a-input v-model="query.spaceName" placeholder="请输入空间名称" />
            </a-form-item>
            <a-form-item>
              <div style="display: flex">
                <SearchResetModule @search="search" @resetSearchForm="resetSearchForm"></SearchResetModule>
                <div
                  style="margin-left: 15px"
                  v-if="$checkButtonPermissions($buttonPermissionsConfig.baseSet.spaceManage.add)"
                >
                  <ds-button :type="$DSBT.DS_ADD" title="新增" @click="addSpace" />
                </div>
              </div>
            </a-form-item>
          </div>
        </a-form>

        <div style="padding-top: 15px">
          <a-table
            :rowKey="(record, index) => record.id"
            :columns="table.columns"
            :data-source="table.dataInfo"
            :pagination="table.pagination.total ? table.pagination : false"
            tableLayout="fixed"
            @change="pageChange"
            bordered
          >
          <template slot="action" slot-scope="text, record"> 
              <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.baseSet.spaceManage.look)"
                  style="color: #3D4966" size="small" type="link" @click="look(record)">查看</a-button>
              <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.baseSet.spaceManage.edit)"
                  style="color: #3377FF" size="small" type="link" @click="edit(record)">编辑</a-button>
              <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.baseSet.spaceManage.delete)"
              style="color: #F05656" size="small" type="link" @click="remove(record)">删除</a-button>
          </template>
          </a-table>
        </div>
        <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from '@/api/baseSet/spaceManage';
import { mapState,mapMutations } from 'vuex';
import ActionConfig from '@/utils/actionConfig';
import ActionModule from '@/components/actionModule';
import ConfirmBoxModule from '@/components/confirmBoxModule';
import ConfirmConfig from '@/utils/confirmConfig';
import SearchResetModule from '@/components/searchResetModule';
import { getParentProjectList } from '@/api/baseSet/projectManage';
const initSearchForm = {
  //项目名称
  project_name: '',
  //项目地址
  project_address: '',
  //透出渠道
  penetrate: undefined,
  //租户id
  tenant_id: '',
};

export default {
  name: 'spaceManage',
  components: {
    ActionModule,
    ConfirmBoxModule,
    SearchResetModule,
  },
  computed: {
    ...mapState({
      villageList:state=>state.space.proList
    }),
  },
  data() {
    return {
      form: {
        projectIds: undefined,
      },
      query: {
        spaceId: '',
        page: 1,
        size: 10,
        spaceName: '',
      },
      replaceTypeTreeFields: { //替换 treenode字段为treedata
        children: 'child',
        title: 'project_name',
        value: 'id',
        key: 'id',
      },
      expandSecondLevel:[],
      expandAll:true,
      defaultSheet:'',
      space_position:'',
      spaceName: '',
      parameterName: '',
      parameterId:'',
      // villageList: [],
      treeData: [],
      treeData1: [],
      key:'0-0',
      //搜索数据
      searchForm: { ...initSearchForm },
      spaceTitle:'',
      //展示数据
      table: {
        //数据
        dataInfo: [],
        //title数据
        columns: [
          {
            title: '空间名称',
            dataIndex: 'spaceName',
            key: 'spaceName',
          },
          {
            title: '空间位置',
            dataIndex: 'spaceLocation',
            key: 'spaceLocation',
          },
          { title: '操作',
            fixed: 'right', 
            width: 200, 
            key: 'action', 
            scopedSlots: { customRender: 'action' } 
          },

        ],
        //分页数据
        pagination: {
          total: 0,
          showSizeChanger: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      //操作集合
      actions: [
        {
          type: ActionConfig.look.ACTION_TYPE,
          title: ActionConfig.look.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.baseSet.spaceManage.look,
        },
        {
          type: ActionConfig.edit.ACTION_TYPE,
          title: ActionConfig.edit.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.baseSet.spaceManage.edit,
        },
        {
          type: ActionConfig.delete.ACTION_TYPE,
          title: ActionConfig.delete.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.baseSet.spaceManage.delete,
        },
      ],
    };
  },
  mounted() {
      if(localStorage.getItem('selectedOption')){
        this.form.projectIds = this.query.spaceId = this.parameterId =localStorage.getItem('selectedOption')
        this.parameterName = this.space_position = localStorage.getItem('selectedName')
      }else{
        this.form.projectIds = this.query.spaceId = this.parameterId = this.villageList[0].id;
        this.parameterName = this.space_position = this.villageList[0].project_name

      }
      this.getList()
      this.getTree()
    // }
  },
  methods: {
    selectHandle(e,v,b) {
      localStorage.setItem('selectedOption', e);
      localStorage.setItem('selectedName', v[0]);
      this.query.spaceId = this.parameterId = e;
      this.parameterName = v[0]
      this.query.page = 1;
      this.query.spaceName = '';
      this.space_position = this.space_position,
      this.getTree();
      this.getList();
    },
    ...mapMutations({
      setList: 'space/setList'
    }),
    treeSelect(e, { node }) {
      this.key = node.pos
      this.spaceTitle = node.dataRef.space_name
      //点击树结构回调
      // this.query.spaceId = e[0];
      this.query.spaceId =node.dataRef.id;
      this.query.spaceName = '';
      this.space_position = this.findAncestorsSpaceNames(this.query.spaceId)
      this.getList();
    },
    findAncestorsSpaceNames(targetId) {
        // 辅助函数，用于递归查找具有目标ID的节点及其父级节点
        function findNodeWithAncestors(node, id, ancestors) {
            if (node.id === id) {
                // 找到目标节点，将当前节点添加到祖先数组并返回
                ancestors.unshift(node);
                return ancestors;
            }
            if (node.son_list) {
                for (let child of node.son_list) {
                    // 递归查找子节点
                    ancestors.unshift(node); // 将当前节点添加到祖先数组
                    const result = findNodeWithAncestors(child, id, ancestors);
                    if (result) {
                        return result; // 如果找到，返回结果
                    }
                    ancestors.shift(); // 如果当前分支没有找到，移除当前节点
                }
            }
            return null; // 如果没有找到，返回null
        }

        // 从根节点开始查找
        const ancestors = findNodeWithAncestors(this.treeData[0], targetId, []);
        if (ancestors) {
            // 如果找到了节点，提取space_name
            return ancestors.map(ancestor => ancestor.space_name).reverse().join('-');
        } else {
            return []; // 如果没有找到，返回空数组
        }
      },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      // this.autoExpandParent = false;
    },
    onSearch(e) {
      this.spaceName = e?.target.value;
      if (this.spaceName) {
        this.treeData = this.searchTree(this.treeData1, this.spaceName);
      } else {
        this.treeData = this.treeData1;
      }
    },
    searchTree(tree, query) {
      function recursiveSearch(nodes) {
        const results = [];

        nodes.forEach((node) => {
          let match = false;
          let son_list = [];

          if (node.son_list && node.son_list.length > 0) {
            son_list = recursiveSearch(node.son_list);
            if (son_list.length > 0) {
              match = true;
            }
          }

          if (node.space_name && node.space_name.includes(query)) {
            match = true;
          }

          if (match) {
            const newNode = { ...node, son_list: son_list.length > 0 ? son_list : null };
            results.push(newNode);
          }
        });

        return results;
      }

      return recursiveSearch(tree);
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    treeHandleClick(e) {
      this.key = e.key
      this.space_position = this.findAncestorsSpaceNames(e.id)
      this.$router.push({
        path: '/baseSet/spaceManage/addSpace',
        query: {
          type:e.id,
          name: this.parameterName,
          id: e.id,
          parentSpaceName: e.space_name,
          top:this.parameterId,
          key:e.key,
          space_position:this.space_position 
        },
      });
    },

    // 查询父级项目
    async getParentProjects(id) {
      let res = await getParentProjectList(id);
      // this.villageList = res.data;
      this.setList(res.data)
      this.form.projectIds = this.query.spaceId = this.villageList[0].id;
      this.parameterName = this.space_position = this.villageList[0].project_name
      this.getTree();
      this.getList();
    },

    async getTree() {
      this.treeData = []
      //查询树
      let res = await api.getTreeSpace({
        superSpaceId: this.parameterId,
      });
      let arr = res.data;
      // 使用递归方法处理数据
      this.addScopedSlots(arr);
      // 将处理后的数据赋值给 treeData
      // 组件挂载后默认展开两层
      arr.forEach(node => {
        if (node.son_list) {
          node.son_list.forEach(item=>{
            this.expandSecondLevel.push(item.id);
          })
          
        }
      });
      this.treeData = arr;
      this.treeData1 = arr;
    },
    addScopedSlots(tree,parentKey = '0-') {
      if (!tree || tree.length === 0) {
        return;
      }

      tree.forEach((node,index) => {
        // 为每个节点添加 scopedSlots
        node.scopedSlots = { title: 'handle' };
        node.open = true;
        node.key = `${parentKey}${index}`;
        // 如果有子节点，递归处理子节点
        if (node.son_list && node.son_list.length > 0) {
          this.addScopedSlots(node.son_list, `${node.key}-`);
        }
      });
    },
    async getList() {
      let res = await api.getSpaceList(this.query);
      this.table.dataInfo = res.data.items;
      this.table.pagination.total = res.data.total;
    },
    
    //查询
    search() {
      this.getList();
    },
    //重置
    resetSearchForm() {
      this.query.spaceName = '';
      this.query.page = 1
      this.getList();
      this.onSearch()
    },
    //添加项目
    addSpace() {
      this.$router.push({
        path: '/baseSet/spaceManage/addSpace',
        query: {
          type:this.query.spaceId,
          name: this.parameterName,
          id: this.query.spaceId,
          parentSpaceName: this.spaceTitle,
          top:this.query.spaceId,
          key:this.key,
          space_position:this.space_position
        },
      });
    },
    //查看
    look(row) {
      this.$router.push({
        path: '/baseSet/spaceManage/lookSpace',
        query:{
          spaceId:row.id,
          space_place:row.spaceLocation,
          name: this.parameterName,
        }
      });
    },
    //编辑
    edit(row) {
      this.$router.push({
        path: '/baseSet/spaceManage/addSpace',
        query:{
          name: this.parameterName,
          id:row.id,
          parentSpaceName:row.spaceName,
          top:this.query.spaceId,
          spaceId:row.id,
          type:this.query.spaceId,
          key:this.key,
          space_position:row.spaceLocation
        }
      });
    },
    //删除
    async remove(text) {
      const confirmData = {
        title: '是否确认删除该空间?',
        content: '删除后该空间及其对应数据将同步删除，请谨慎操作',
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.getDelete(text.id);
    },
    //列表操作
    action(actionType, data) {
      if (actionType === ActionConfig.look.ACTION_TYPE) {
        this.look(data);
      } else if (actionType === ActionConfig.edit.ACTION_TYPE) {
        this.edit(data);
      } else if (actionType === ActionConfig.delete.ACTION_TYPE) {
        this.remove(data);
      }
    },
    //删除项目
    async getDelete(id) {
      try {
       let res =  await api.deleteSpace({spaceId:id});
        if(res.code == 200){
          this.$message.success('删除成功');
          this.getList();
          this.getTree()
        }else{
          this.$message.error(res.msg);
        }
        
      } catch (error) {
        this.$message.error('删除失败');
      }
    },
    //分页操作
    pageChange(option) {
      this.query.page = option.current;
      this.query.size = option.pageSize;
      this.getList();
    },
  

    
  },
};
</script>

<style lang="scss" scoped>
.tree_name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px; /* 根据需要调整 */
  display: inline-block;
}
</style>
